<template>
  <v-sheet>
    <v-sheet v-if="!editing_name" class="d-flex align-center">
      <v-btn
        text
        small
        @click="$router.push('/tiimi-admin/leagues')"
        class="ml-2"
      ><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-card-title @dblclick="editing_name = true">
        {{leagueName}}
      </v-card-title>
      <v-btn 
        text 
        fab
        small
        @click="editing_name = true"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet v-else class="px-4" max-width="600">
      <v-text-field 
        @input="new_name = $event" 
        @blur="editing_name = false" 
        :value="leagueName" 
        append-icon="mdi-check" 
        @click:append="changeName"
      >
      </v-text-field>
    </v-sheet>
    <v-sheet class="pa-5">
      <v-sheet>
        <v-btn
          v-for="btn in btns"
          :key="btn.path + 'btn'"
          text
          :disabled="btn.disabled"
          small
          :color="$route.path.includes(btn.path) ? 'indigo' : ''" 
          @click="$router.push(`/tiimi-admin/leagues/${$route.params.league_id}/${btn.path}`)"
        >
          {{ btn.name }}
        </v-btn>
      </v-sheet>
      <v-sheet>
        <router-view></router-view>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    // this.initGames({league_id: this.$route.params.league_id, season: 'all'})
  },
  data: () => ({
    editing_name: false,
    new_name: '',
    btns: [
      { path: 'games', name: 'Games', disabled: false },
      { path: 'clubs', name: 'Clubs', disabled: false },
      { path: 'teams', name: 'Teams', disabled: false },
      { path: 'seasons', name: 'Seasons', disabled: false },
      { path: 'players', name: 'Players', disabled: false },
      { path: 'configuration', name: 'Configuration', disabled: false },
    ]
  }),
  computed: {
    ...mapGetters('admin', [
      'leagues',
      'games',
      'leagueById'
    ]),
    leagueName() {
      return this.leagueById(this.$route.params.league_id)?.league_name
    },
  },
  methods: {
    ...mapActions('admin', [
      'initGames',
      'updateLeague'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    changeName() {
      if(!this.new_name || !this.new_name.length) {
        this.editing_name = false
        return
      }

      const league = this.leagueById(this.$route.params.league_id)

      this.updateLeague({
        id: league.id,
        league_name: this.new_name
      })
      .then(() => {
        this.success('league name changed')
        this.editing_name = false
      })
      .catch(() => {
        this.error('something went wrong')
      })
    }
  }
}
</script>